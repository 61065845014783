<template>
  <view> </view>
</template>
<script>
import Gateway from "./order-gateway-controller";
import { loading , clearLoading} from "../utils/loading";

export default {
  created() {},
  setup() {},
  beforeRouteEnter(to, from, next) {
    const orderId = to.params.orderId;
    const { code, client, jumpUrl } = to.query;
    loading();
    Gateway.gateway({ code, client, orderId, jumpUrl }).then((value) => {
        next(value);
        clearLoading();
      } , () => {
        next({ path: "/404" });
        clearLoading();
      } 
    );
  },
};
</script>