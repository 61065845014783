import OrderService from "../api/order-service";
import User from "../api/user"
import { userLoginTimeOutInit } from "../api/user-timeout";

function gateway({ code, client, orderId, jumpUrl}) {
    return new Promise((resolve, reject) => {
        const data = { code, client, jumpUrl};
        User.login(data)
            .then(() => OrderService.getOrderByEcOrderId(orderId))
            .then(order => {
                userLoginTimeOutInit(data);
                if (order.bookingId) {
                    resolve({ path: "/restaurant-booking-details/" + order.bookingId });
                } else if (order.orderId && (order.status == "UNPAID" || order.status == "PAYING")) {
                    resolve({ path: "/order/" + order.orderId + "/payment" });
                } else {
                    resolve({ path: "/order/" + order.orderId + "/card" });
                }
            }, reject);
    })
}

export default {
    gateway
}